import React from "react";
import { Link } from "gatsby";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import BlockText from "./block-text";
import Container from "./container";

import * as styles from "./article.module.css";

const renderFeaturedImage = featuredImage => {
  let img = null;
  if (featuredImage && featuredImage.asset) {
    const imageData = getGatsbyImageData(
      featuredImage,
      { maxWidth: 1800 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        className={styles.featuredImage}
        image={imageData}
        alt={featuredImage.alt}
      />
    );
  }
  return img;
};

function Article({
  title,
  _rawBody, 
  featuredImage,
  _rawCredits
}) {
  const image = renderFeaturedImage(featuredImage);

  return (
    <>
      <div className={styles.titleSection}>
        <div className={styles.titleSectionSide}>
          <h1 className={styles.title}>{title}</h1>
          {_rawCredits && (
            <div className={styles.credits}>
              <BlockText blocks={_rawCredits} />
            </div>
          )}
        </div>
        <div className={styles.titleSectionSide}>
          {image}
        </div>
      </div>
      {_rawBody && (
        <div className={styles.content}>
          <BlockText blocks={_rawBody} />
        </div>
      )}
      <Container>
        <hr />
        <Link to="/news" className={styles.returnLink}>&#x2190; Return to news</Link>
      </Container>
    </>
  );
}

export default Article;
